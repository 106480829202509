
module.exports = {
    roiGetter: (fig1, fig2) => {
        // console.log(rev)
        if (!fig1 || !fig2) {
            return 0.0;
        }
        else {
            return parseFloat(((fig1 / fig2) / 100).toFixed(2));
        }
    },
    percentageOf: (fig1, fig2) => {
        if (!fig1 || !fig2) {
            return 0
        }
        return Math.floor(fig1 / fig2 * 100);
    },
    percentageFormatter: (fig) => {
        // Note that !fig will be true if fig is 0
        if (!fig) {
            return "0.00%";
        }
        return fig.toFixed(2) + "%"
    },
    adservRatio: (fig1, fig2) => {
        if (!fig1 || !fig2) {
            return 0
        }
        return parseFloat((fig1/fig2).toFixed(2));
    },
    currencyFormatter: (currency, sign) => {
        if (currency === '' || currency === null || currency === undefined) {
            return `${sign}0.00`;
        }

        var sansDec = `${currency.toFixed(2)}`;
        var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (currency < 0) {
            return `(${sign}${formatted.replace("-", "")})`
        }
        return sign + `${formatted}`;
    },
    // Return default if there is no val
    ifNull: (val, def) => {
        // console.log("Null Check Val: ", val);
        if (!val) {
            return 0
        }
        return def;
    },
    // Loop through list and change any null/undefined values to 0
    cleanNulls: (data) => {
        let cleanedData = [];
        for(let i = 0;i<data.length;i++) {
            let row = data[i];
            if(row === undefined) {
                continue;
            } 
            Object.entries(row).forEach(([key, value]) => {
                if(!value || value === undefined) {
                    row[key] = 0;
                }
            });
            cleanedData.push(row);
        }
        return cleanedData;
    },

    // Given a date string, this function will return a date string as YYYY-MM-DD
    // Expected input: YYYY-MM-DDTHH:MM:SSZ
    dateFormatter: (val) => {
        if (!val) {
            return "";
        }
        
        // 
        var dateParts = val.split('T');
        return `${dateParts[0]}`;
    },

    /**
     * So when the frontend receives the timestamp with timezone, it looks like the value 
     * is actually being converted to PST but the 'Z' is still there. We just want to remove that 'Z'
     * Expected input: YYYY-MM-DDTHH:MM:SS.MMMZ
     * @param {*} val 
     * Input value
     * @returns 
     * A string in the format of YYYY-MM-DD HH:MM:SS-0800
     */
    dateFormatterAddPSTSuffix: (val) => {
        if (!val) {
            return "";
        }
        
        return val.split('.')[0].replace("Z", "") + "-0800"; // Remove milliseconds
    },


    // Given seconds, return the value as hours. E.g. 60 secs -> 0.17 hour 
    convertToHours: (val) => {
        if (!val) {
            return 0.0;
        }

        return parseFloat((val/60/60).toFixed(2));
    },
    
    dateFormatterWithPST: (val) => {
        if (!val) {
            return "";
        }
    
        const date = new Date(val);
        const options = {
            timeZone: 'America/Los_Angeles',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h24'
        };
    
        const formattedDate = date.toLocaleString('en-US', options);
        return `${formattedDate}-0800`;
    },

    dateFormatterWithAMPM: (val) => {
        if (!val) {
            return "";
        }
    
        const date = new Date(val);
        const options = {
            timeZone: 'America/Los_Angeles',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: 'numeric',
            hourCycle: 'h12'
        };
    
        const formattedDate = date.toLocaleString('en-US', options);
        const [dateString, timeString] = formattedDate.split(', ');
        const [time, ampm] = timeString.split(' ');
    
        return `${dateString} ${time} ${ampm.toUpperCase()}`;
    },
    
}